export function brightenHex(hex: string, percent: number) {
  // 1. Validate and preprocess the hex code
  hex = hex.replace("#", "");
  if (hex.length !== 6) {
    throw new Error("Invalid hex color code");
  }

  // 2. Convert hex to RGB components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // 3. Calculate the brightening factor
  const brighteningFactor = 1 + percent / 100;

  // 4. Calculate the new brighter RGB components (clamped to stay within 0-255)
  const newR = Math.min(255, Math.round(r * brighteningFactor));
  const newG = Math.min(255, Math.round(g * brighteningFactor));
  const newB = Math.min(255, Math.round(b * brighteningFactor));

  // 5. Convert the brightened RGB components back to hex
  const newHex =
    "#" + newR.toString(16).padStart(2, "0") + newG.toString(16).padStart(2, "0") + newB.toString(16).padStart(2, "0");

  return newHex;
}
