import { ReactNode, useId } from "react";
import HomeworkRow from "../HomeworkRow/HomeworkRow";
import HomeworkModel from "../../models/homeworkModel";
import "./homeworkSection.scss";

interface IHomeworkSection {
  homeworkList: HomeworkModel[];
  title: string;
  description: string;
  headerIcon: ReactNode;
  disabled?: boolean;
  onHomeworkSelect?: (homework: HomeworkModel) => void;
  index: number;
}

const HomeworkSection: React.FC<IHomeworkSection> = ({
  homeworkList,
  title,
  description,
  headerIcon,
  index,
  onHomeworkSelect,
  disabled = false,
}) => {
  return (
    <div className="hw-section-container" id={`hw-section-${index}`}>
      <div className="hw-section-container__title">
        <div className="hw-section-container__title__index">{headerIcon}</div>
        <div className="hw-section-container__title__text">{title}</div>
      </div>
      <div className="hw-section-container__description">{description}</div>
      <div className="hw-section-container__divider" />
      <div>
        {homeworkList.map((homework, index) => (
          <HomeworkRow
            key={index}
            homework={homework}
            onHomeworkSelect={onHomeworkSelect}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeworkSection;
