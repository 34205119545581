import CodeIcon from "@mui/icons-material/Code";
import { useNavigate } from "react-router-dom";
import HomeworkModel from "../../models/homeworkModel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./homeworkRow.scss";
import { Tooltip } from "@mui/material";

interface IHomeworkRow {
  homework: HomeworkModel;
  disabled?: boolean;
  onHomeworkSelect?: (homework: HomeworkModel) => void;
}

const HomeworkRow: React.FC<IHomeworkRow> = ({
  homework,
  onHomeworkSelect,
  disabled = false,
}) => {
  const navigate = useNavigate();

  const handleExerciseClick = () => {
    if (disabled) return;

    if (onHomeworkSelect) {
      onHomeworkSelect(homework);
      return;
    }

    navigate("/student/homework/" + homework.id);
  };

  return (
    <div
      className={`hw-row-container ${disabled ? "disabled" : ""}`}
      onClick={handleExerciseClick}
    >
      <div className="hw-row-container__icon">
        <CodeIcon fontSize="small" />
      </div>
      <div className="hw-row-container__text">{homework.title}</div>
      <div className="hw-row-container__submit-status">
        {homework.is_user_submitted ? (
          <Tooltip title="You have submitted this homework, also you can submit again">
            <CheckCircleOutlineIcon />
          </Tooltip>
        ) : (
          <Tooltip title="You didn't submit this homework">
            <RadioButtonUncheckedIcon />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default HomeworkRow;
