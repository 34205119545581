import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Portal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postInstructorUpdateStudent } from "../../../../../services/instructor.api/student";
import { StudentModel } from "../../../../../contracts/models/instructor/StudentModel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface IEditStudent {
  student: StudentModel;
  toggleModal: () => void;
  toggleGrid: () => void;
}

const EditStudent: React.FC<IEditStudent> = ({ student, toggleModal, toggleGrid }) => {
  const [firstName, setFirstName] = useState<string>(student.first_name);
  const [lastName, setLastName] = useState<string>(student.last_name);
  const [newPassword, setNewPassword] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = () => setShowPassword((prevVal) => !prevVal);

  const [updatePassword, setUpdatePassword] = useState<boolean>(false);

  const [errorTxt, setErrorTxt] = useState<string>();

  useEffect(() => {
    setFirstName(student.first_name);
    setLastName(student.last_name);
    setShowPassword(false);
    setUpdatePassword(false);
  }, [student]);

  const isFormValid = () => {
    if (firstName.length < 3 || lastName.length < 2) {
      setErrorTxt("Please enter a valid first name or last name");
      return false;
    } else if (updatePassword && newPassword.length < 8) {
      setErrorTxt("Please enter password at least 8 characters length");
      return false;
    }

    return true;
  };

  const handleUpdate = () => {
    if (isFormValid()) {
      setLoading(true);
      postInstructorUpdateStudent(student.id, firstName, lastName, updatePassword ? newPassword : undefined).then(() => {
        setSuccess(true);
        toggleGrid();
        toggleModal();
        setNewPassword("");
      });
    }
  };

  return (
    <>
      <div>
        <Typography variant="h6">Update Student</Typography>
        <Typography variant="body1" className="!mt-2">
          <b>Email: </b> {student.username}
        </Typography>
        <TextField
          label="First Name"
          className="!mt-6"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
        />
        <TextField label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth />
        <FormControlLabel
          control={
            <Checkbox
              checked={updatePassword}
              onChange={(event, checked) => {
                setUpdatePassword(checked);
                setNewPassword("");
              }}
            />
          }
          label="Update Password"
        />
        <TextField
          value={newPassword}
          placeholder="New Password"
          type={showPassword ? "text" : "password"}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          disabled={!updatePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={togglePassword} onMouseDown={togglePassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" onClick={handleUpdate}>
            Update
          </Button>
        </div>
      </div>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={isSuccess}
          onClose={() => setSuccess((prevVal) => !prevVal)}
          autoHideDuration={3000}
          key={"topright"}
        >
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            Student password successfully changed
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!errorTxt}
          onClose={() => setErrorTxt(undefined)}
          autoHideDuration={3000}
          key={"topright"}
        >
          <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
            {errorTxt}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default EditStudent;
