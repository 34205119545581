import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserModel from "../models/userModel";
import ClassModel from "../models/classModel";

interface UserStore {
  userData?: UserModel;
  classData?: ClassModel;
}

const initialState: UserStore = {
  userData: undefined,
  classData: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserModel | undefined>) => {
      state.userData = action.payload;
    },
    setClassData: (state, action: PayloadAction<ClassModel | undefined>) => {
      state.classData = action.payload;
    },
  },
});

export const { setUserData, setClassData } = userSlice.actions;

export default userSlice.reducer;
