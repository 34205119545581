// src/contexts/AuthContext.tsx
import React, { createContext, useState, useEffect } from "react";
import { StudentModel } from "../contracts/models/instructor/StudentModel";
import { SubmissionModel } from "../models/submissionModel";

interface InstructorContentContextType {
  currentTabIndex: number;
  setCurrentTabIndex: React.Dispatch<React.SetStateAction<number>>;
  studentList: StudentModel[];
  setStudentList: React.Dispatch<React.SetStateAction<StudentModel[]>>;
  studentPage: number;
  setStudentPage: React.Dispatch<React.SetStateAction<number>>;
  hwSubmissionList: SubmissionModel[];
  setHwSubmissionList: React.Dispatch<React.SetStateAction<SubmissionModel[]>>;
  hwPage: number;
  setHWPage: React.Dispatch<React.SetStateAction<number>>;
}

export const InstructorContentContext = createContext<InstructorContentContextType>({
  currentTabIndex: 0,
  setCurrentTabIndex: () => {},
  studentList: [],
  setStudentList: () => {},
  studentPage: 1,
  setStudentPage: () => {},
  hwSubmissionList: [],
  setHwSubmissionList: () => {},
  hwPage: 1,
  setHWPage: () => {},
});

interface InstructorcontentProps {
  children: React.ReactNode;
}

export const InstructorContentProvider: React.FC<InstructorcontentProps> = ({ children }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [studentList, setStudentList] = useState<StudentModel[]>([]);
  const [studentPage, setStudentPage] = useState<number>(1);
  const [hwSubmissionList, setHwSubmissionList] = useState<SubmissionModel[]>([]);
  const [hwPage, setHWPage] = useState<number>(1);

  return (
    <InstructorContentContext.Provider
      value={{
        currentTabIndex,
        setCurrentTabIndex,
        studentList,
        setStudentList,
        studentPage,
        setStudentPage,
        hwSubmissionList,
        setHwSubmissionList,
        hwPage,
        setHWPage,
      }}
    >
      {children}
    </InstructorContentContext.Provider>
  );
};
