import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { postToken } from "../../services/common.api/auth";
import { AxiosError, AxiosResponse } from "axios";
import { getCommonUserDetail } from "../../services/common.api/user";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/userSlice";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { RootState } from "../../redux/types";
import "./signinPage.scss";
import { Colors } from "../../style/colors";
import { brightenHex } from "../../utils/color";

const SigninPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorSnack, setErrorSnack] = useState<boolean>(false);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn } = useContext(AuthContext);
  const { userData: user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (isLoggedIn) {
      if (user?.role === "student") {
        navigate("/student/class-detail");
      } else {
        navigate("/instructor/class-detail");
      }
    }
  }, [isLoggedIn]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    postToken(email, password)
      .then((value: AxiosResponse) => {
        if (value.data.access) {
          login(value.data.access);
          getCommonUserDetail().then((response: AxiosResponse) => {
            const user = response.data;
            if (user.role === "instructor") {
              // @todo: Now assuming one instructor can have only one class
              user.classId = user.class_list[0].id;
            }

            dispatch(setUserData(user));
          });
        }
      })
      .catch((error: AxiosError) => {
        console.error("Error while getToken api ", error);
        setError(error.request.status === 401 ? "Credential Error" : error.message);
        setErrorSnack(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="signin">
        <div className="signin__card">
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <TextField
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              placeholder="Email"
              required
              fullWidth
            />

            <label htmlFor="password">Password</label>
            <TextField
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              placeholder="Password"
              required
              type="password"
              fullWidth
            />

            <Button
              variant="contained"
              sx={{
                marginTop: "12px",
                backgroundColor: Colors.SECONDARY,
                color: Colors.PRIMARY,
                fontWeight: "bold",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: brightenHex(Colors.SECONDARY, 20),
                },
              }}
              type="submit"
              disabled={isLoading}
              fullWidth
            >
              {isLoading ? "Signing In..." : "Sign In"}
            </Button>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={errorSnack}
        onClose={() => setErrorSnack((prevVal) => !prevVal)}
        autoHideDuration={3000}
        key={"topright"}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SigninPage;
