import { httBaseAuth } from "../../utils/httpBase";

export const getInstructorStudentList = (id: number, pageNum: number) => {
  return httBaseAuth().get(`/instructor/student-list/${id}/${pageNum}/`);
};

export const postInstructorRegisterStudent = (
  class_id: number,
  username: string,
  first_name: string,
  last_name: string,
  password: string
) => {
  return httBaseAuth().post("/instructor/register-student/", {
    class_id,
    username,
    first_name,
    last_name,
    password,
  });
};

export const postInstructorDeleteStudent = (student_id: number) => {
  return httBaseAuth().post("/instructor/delete-student/", { student_id });
};

export const postInstructorUpdateStudent = (student_id: number, first_name: string, last_name: string, new_password?: string) => {
  return httBaseAuth().post("/instructor/update-student/", {
    student_id,
    first_name,
    last_name,
    new_password,
  });
};
