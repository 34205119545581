import { useContext, useEffect, useState } from "react";
import { InstructorContentContext } from "../../../../../contexts/InstructorContentContext";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/types";
import NewStudent from "../NewStudent/NewStudent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditStudent from "../EditStudent/EditStudent";
import { postInstructorDeleteStudent, getInstructorStudentList } from "../../../../../services/instructor.api/student";
import PaperModal from "../../../../../components/PaperModal/PaperModal";
import { AxiosResponse } from "axios";
import { StudentListResponse } from "../../../../../contracts/responses/instructor/StudentListResponse";
import { StudentModel } from "../../../../../contracts/models/instructor/StudentModel";
import { DEFAULT_PAGINATION_COUNT } from "../../../../../utils/constants";
import PaginationRow from "../../../../../components/PaginationRow/PaginationRow";
import "./StudentList.scss";

const StudentList = () => {
  const { currentTabIndex, studentPage, setStudentPage, studentList, setStudentList } = useContext(InstructorContentContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [newStudentModal, setNewStudentModal] = useState<boolean>(false);
  const [editStudentModal, setEditStudentModal] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<StudentModel>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const classId = useSelector((state: RootState) => state.user.userData?.classId);

  const [refreshList, setRefreshList] = useState<boolean>(false);
  const toggleGrid = () => setRefreshList((prevVal) => !prevVal);

  const toggleNewStudentModal = () => setNewStudentModal((prevVal) => !prevVal);
  const toggleEditStudentModal = () => setEditStudentModal((prevVal) => !prevVal);

  useEffect(() => {
    if (classId) {
      setLoading(true);
      getInstructorStudentList(classId, studentPage)
        .then((response: AxiosResponse<StudentListResponse>) => {
          setStudentList(response.data.student_list);
          setTotalCount(response.data.total_count);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }
  }, [studentPage, refreshList, classId]);

  const handleDeleteClick = (student: StudentModel) => {
    if (
      window.confirm(
        `Are you sure want to remove ${student.username} from class? This student and all his/her submissions will be deleted.`
      )
    ) {
      setLoading(true);
      postInstructorDeleteStudent(student.id)
        .then(() => {
          toggleGrid();
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }
  };

  const handleEditClick = (student: StudentModel) => {
    setSelectedStudent(student);
    toggleEditStudentModal();
  };

  return currentTabIndex === 0 ? (
    <div className="student-list">
      {isLoading ? (
        <div className="student-list__circular">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="student-list__header">
            <Button variant="contained" onClick={toggleNewStudentModal}>
              + New Student
            </Button>
          </div>
          <div className="student-list__grid">
            <TableContainer component={Paper}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Id</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Full Name</b>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList?.map((student: StudentModel) => {
                  return (
                    <TableRow key={student.id}>
                      <TableCell>{student.id}</TableCell>
                      <TableCell>{student.username}</TableCell>
                      <TableCell width={"100%"}>{(student.first_name ?? "") + " " + (student.last_name ?? "")}</TableCell>
                      <TableCell sx={{ display: "flex", flexDirection: "row" }}>
                        <IconButton onClick={() => handleEditClick(student)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(student)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableContainer>
            <PaginationRow
              page={studentPage}
              count={Math.ceil(totalCount / DEFAULT_PAGINATION_COUNT)}
              onChange={(event, page) => setStudentPage(page)}
            />
          </div>
          <PaperModal open={newStudentModal} onClose={toggleNewStudentModal}>
            <NewStudent toggleModal={toggleNewStudentModal} toggleGrid={toggleGrid} />
          </PaperModal>
          <PaperModal open={editStudentModal} onClose={toggleEditStudentModal}>
            {!!selectedStudent ? (
              <EditStudent student={selectedStudent} toggleModal={toggleEditStudentModal} toggleGrid={toggleGrid} />
            ) : (
              <></>
            )}
          </PaperModal>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default StudentList;
