import { Button } from "@mui/material";
import PageHeaderBar from "../../../components/PageHeaderBar/PageHeaderBar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CodeIcon from "@mui/icons-material/Code";
import HomeworkSection from "../../../components/HomeworkSection/HomeworkSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../../redux/types";
import { setClassData } from "../../../redux/userSlice";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import { AxiosError, AxiosResponse } from "axios";
import ClassModel from "../../../models/classModel";
import "./classDetail.scss";
import { getStudentClassDetail } from "../../../services/student.api/class";
import { Colors } from "../../../style/colors";
import { brightenHex } from "../../../utils/color";
import CourseMaterialList from "../../../components/CourseMaterialList/CourseMaterialList";

interface IClassDetailPage {}

const ClassDetailPage: React.FC<IClassDetailPage> = ({}) => {
  const dispatch = useDispatch();

  const { userData: user } = useSelector((state: RootState) => state.user);
  const classDetail = useSelector((state: RootState) => state.user.classData);

  useEffect(() => {
    if (user?.classId) {
      getStudentClassDetail(user?.classId)
        .then((response: AxiosResponse<ClassModel>) => {
          dispatch(setClassData(response.data));
        })
        .catch((error: AxiosError) => console.error("Error while fetching class details"));
    }
  }, [user?.classId]);

  return (
    <>
      <PageHeaderBar />
      <div className="class-container">
        <div className="class-container__aligner">
          <div className="class-container__main-card">
            <div className="class-container__main-card__up-title">Class Detail</div>
            <div className="class-container__main-card__title">{classDetail?.name}</div>
            <div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: Colors.SECONDARY,
                  color: Colors.PRIMARY,
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: brightenHex(Colors.SECONDARY, 20),
                  },
                }}
                onClick={() => {
                  document.getElementById("hw-section-1")?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Go to Homeworks
              </Button>
            </div>
            <div className="class-container__main-card__meta">
              <div className="class-container__main-card__meta__level">
                <span
                  className={"class-container__main-card__meta__level__icon class-container__main-card__meta__level__beginner"}
                />
                <span>{classDetail?.is_active ? "Active" : "Expired"}</span>
              </div>
              <div className="class-container__main-card__meta__chip">
                <AccessTimeIcon fontSize="small" />
                {classDetail?.total_week_count} Weeks
              </div>
              <div className="class-container__main-card__meta__chip">
                <CodeIcon fontSize="small" />
                {(classDetail?.active_hw_list?.length ?? 0) + (classDetail?.not_active_hw_list?.length ?? 0)}
                &nbsp;Total Homeworks
              </div>
            </div>
          </div>
          <div className="class-container__description">
            <div className="class-container__description__title">Course Description</div>
            <div className="class-container__description__text">{classDetail?.course_description}</div>
          </div>
          <HomeworkSection
            title="Active Homeworks"
            headerIcon={<TaskAltIcon fontSize="small" />}
            description="Here's where you'll find all active homework assignments. Navigate through this hub for quick access and stay on top of your academic tasks."
            homeworkList={classDetail?.active_hw_list ?? []}
            index={0}
          />
          <HomeworkSection
            title="Expired Homeworks"
            headerIcon={<CodeOffIcon fontSize="small" />}
            description="Explore the archive for past homework assignments. This centralized space offers easy access, allowing you to review and reflect on completed tasks. Navigate through to find and revisit your expired homeworks efficiently."
            homeworkList={classDetail?.not_active_hw_list ?? []}
            disabled
            index={1}
          />
          <CourseMaterialList />
        </div>
      </div>
    </>
  );
};

export default ClassDetailPage;
