import { Alert, Button, IconButton, InputAdornment, Portal, Snackbar, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/types";
import { AxiosError, AxiosResponse } from "axios";
import { postInstructorRegisterStudent } from "../../../../../services/instructor.api/student";
import { isValidEmail } from "../../../../../utils/validation";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./NewStudent.scss";

interface INewStudent {
  toggleModal: () => void;
  toggleGrid: () => void;
}

const NewStudent: React.FC<INewStudent> = ({ toggleModal, toggleGrid }) => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccess, setSuccess] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = () => setShowPassword((prevVal) => !prevVal);

  const [errorTxt, setErrorTxt] = useState<string>();

  const { userData } = useSelector((state: RootState) => state.user);

  const isFormValid = () => {
    if (!isValidEmail(email)) {
      setErrorTxt("Please enter a valid email");
      return false;
    } else if (firstName.length < 3 || lastName.length < 2) {
      setErrorTxt("Please enter valid first name and last name");
      return false;
    } else if (password.length < 8) {
      setErrorTxt("Please enter password at least 8 characters length");
      return false;
    }

    return true;
  };

  const handleRegister = () => {
    if (userData?.classId && isFormValid()) {
      setLoading(true);
      postInstructorRegisterStudent(userData?.classId, email, firstName, lastName, password)
        .then((response: AxiosResponse) => {
          if (response.status === 201) {
            setSuccess(true);
            toggleModal();
            toggleGrid();
          }
        })
        .catch((error: AxiosError) => {
          setErrorTxt((error?.response?.data as string[])?.join(" "));
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div className="new-student">
        <Typography variant="h6">Add New Student</Typography>
        <div className="new-student__input">
          <TextField type="email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth placeholder="Email" />
          <TextField
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            placeholder="First Name"
          />
          <TextField
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            placeholder="Last Name"
          />
          <TextField
            value={password}
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={togglePassword} onMouseDown={togglePassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="new-student__footer">
          <Button variant="contained" onClick={handleRegister} disabled={isLoading}>
            {isLoading ? "Wait..." : "Register"}
          </Button>
        </div>
      </div>
      <Portal>
        <Snackbar
          open={isSuccess}
          onClose={() => setSuccess((prevVal) => !prevVal)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          key="bottomright"
        >
          <Alert severity="success" variant="filled" className="w-full">
            Student succesfully registered
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={!!errorTxt}
          onClose={() => setErrorTxt(undefined)}
          autoHideDuration={3000}
          key={"topright"}
        >
          <Alert severity="error" variant="filled" className="w-full">
            {errorTxt}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default NewStudent;
