import { useNavigate, useParams } from "react-router-dom";
import HWDetailHeaderBar from "../../../components/HWDetailHeaderBar/HWDetailHeaderBar";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PyodideEditor from "../../../components/PyodideEditor/PyodideEditor";
import { useEffect, useState } from "react";
import { Box, Button, Modal, Tooltip } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import SubmitChecker from "../../../components/SubmitChecker/SubmitChecker";
import TestCaseResultModel from "../../../models/submitResultModel";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { getStudentHomeworkDetail, postStudentSendSubmission } from "../../../services/student.api/homework";
import HomeworkModel from "../../../models/homeworkModel";
import { AxiosResponse } from "axios";
import "./homeworkDetailPage.scss";
import { Colors } from "../../../style/colors";
import { brightenHex } from "../../../utils/color";
import { ColorizeSharp } from "@mui/icons-material";

const paperModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const transparentModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "transparent",
  p: 4,
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

interface IHomeworkDetailPage {}

const HomeworkDetailPage: React.FC<IHomeworkDetailPage> = ({}) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [toggleCompile, setToggleCompile] = useState<boolean>(false);
  const [toggleResetCode, setToggleResetCode] = useState<boolean>(false);

  const [isCompileLoading, setCompileLoading] = useState<boolean>(false);
  const [outputLog, setOutputLog] = useState<string>("");

  const [userCode, setUserCode] = useState<string>("");
  const [isSubmitInProgress, setSubmitInProgress] = useState<boolean>(false);

  const [submitResult, setSubmitResult] = useState<TestCaseResultModel[]>();
  const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);

  const [homework, setHomework] = useState<HomeworkModel>();

  useEffect(() => {
    getStudentHomeworkDetail(id ?? "").then((response: AxiosResponse<HomeworkModel>) => {
      if (response.data.is_expired) {
        alert("Homework Expired");
        navigate("/");
        return;
      }

      setHomework(response.data);
    });
  }, []);

  useEffect(() => {
    if (toggleResetCode) {
      setToggleResetCode(false);
    }
  }, [toggleResetCode]);

  const handleRunClick = () => {
    setOutputLog("");

    setCompileLoading(true);
    setToggleCompile(true);
  };

  const handleSubmitClick = () => {
    setOutputLog("");
    setSubmitInProgress(true);
  };

  const onSubmitCheckEnd = async (result: TestCaseResultModel[]) => {
    if (result?.every((value) => value.result)) {
      await postStudentSendSubmission(id ?? "", userCode)
        .then((response) => {})
        .catch((error) => console.error(error));
    }

    setSubmitInProgress(false);
    setSubmitResult(result);
    setSubmitModalOpen(true);
  };

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <>
      <div className="homework">
        <HWDetailHeaderBar homework={homework} />
        <div className="homework__container">
          <div className="homework__container__left">
            <div className="homework__container__left__top">
              <div className="homework__container__left__section-title">
                <LibraryBooksOutlinedIcon fontSize="small" />
                Homework
              </div>
              <div className="homework__container__left__top__body">
                <div className="homework__container__left__top__body__title">{homework?.title}</div>
                <pre className="homework__container__left__top__body__description">{homework?.description}</pre>
              </div>
            </div>
            <div className="homework__container__left__bottom">
              <div className="homework__container__left__section-title">
                <CheckCircleOutlineOutlinedIcon fontSize="small" />
                Instructions
              </div>
              <div className="homework__container__left__bottom__body">
                <pre className="homework__container__left__bottom__body__description">{homework?.instructions}</pre>
              </div>
            </div>
          </div>
          <div className="homework__container__right">
            <div className="homework__container__right__top">
              <div className="homework__container__right__top__header">
                <div className="homework__container__right__top__header__file">script.py</div>
              </div>
              <div className="homework__container__right__top__code">
                <PyodideEditor
                  toggleCompile={toggleCompile}
                  onCompileEnd={(log: string) => {
                    setOutputLog(log);

                    setToggleCompile(false);
                    setCompileLoading(false);
                  }}
                  defaultCode={homework?.template_code}
                  resetToggle={toggleResetCode}
                  setCode={setUserCode}
                />
                <div className="homework__container__right__top__code__actions">
                  <Tooltip title="Reset to sample code">
                    <Button
                      disabled={isCompileLoading || isSubmitInProgress}
                      sx={{
                        color: "white",
                        borderColor: "white",
                        "&:hover": {
                          color: "white",
                          borderColor: "white",
                          background: Colors.DARK_BLUE,
                        },
                      }}
                      variant="outlined"
                      onClick={() => {
                        setToggleResetCode(true);
                        setOutputLog("");
                      }}
                    >
                      <RefreshOutlinedIcon />
                    </Button>
                  </Tooltip>
                  <Button
                    sx={{
                      color: "white",
                      borderColor: "white",
                      "&:hover": {
                        color: "white",
                        borderColor: "white",
                        background: Colors.DARK_BLUE,
                      },
                      "&:disabled": {
                        color: "white",
                        borderColor: "white",
                        background: Colors.DARK_BLUE,
                        opacity: 0.6,
                      },
                    }}
                    variant="outlined"
                    onClick={handleRunClick}
                    disabled={isCompileLoading || isSubmitInProgress}
                  >
                    {isCompileLoading ? "Loading..." : "Run Code"}
                  </Button>
                  <Button
                    disabled={isCompileLoading || isSubmitInProgress}
                    sx={{
                      background: Colors.SECONDARY,
                      color: Colors.DARK_BLUE,
                      "&:hover": {
                        background: brightenHex(Colors.SECONDARY, 20),
                      },
                      "&:disabled": {
                        background: Colors.SECONDARY,
                        opacity: 0.6,
                      },
                    }}
                    variant="contained"
                    onClick={handleSubmitClick}
                  >
                    {isSubmitInProgress ? "Loading..." : "Submit Answer"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="homework__container__right__bottom">
              <div className="homework__container__right__top__header">
                <div className="homework__container__right__top__header__file">Python Shell</div>
              </div>
              <div className="homework__container__right__bottom__log">
                <pre>{outputLog}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isSubmitInProgress && (
        <SubmitChecker
          testCases={JSON.parse(homework?.test_cases ?? "") ?? []}
          userCode={userCode}
          onSubmitCheckEnd={onSubmitCheckEnd}
        />
      )}

      <Modal open={submitModalOpen} onClose={() => setSubmitModalOpen(false)}>
        <Box sx={submitResult?.every((value) => value.result) ? transparentModalStyle : paperModalStyle}>
          {submitResult?.every((value) => value.result) ? (
            <div className="congrat-modal">
              <TaskAltIcon fontSize="large" />
              <div>Great! You have completed the homework!</div>

              <div>
                <div className="congrat-modal__continue-text">Class Detail Page</div>
                <Button
                  fullWidth
                  sx={{
                    fontWeight: "bold",
                    background: Colors.SECONDARY,
                    color: Colors.DARK_BLUE,
                    "&:hover": {
                      background: brightenHex(Colors.SECONDARY, 20),
                    },
                    "&:disabled": {
                      background: Colors.SECONDARY,
                      opacity: 0.6,
                    },
                  }}
                  variant="contained"
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : (
            <div>Submit is failed. Please check your answer</div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default HomeworkDetailPage;
