import { useEffect, useRef, useState } from "react";
import { PyodideInterface, loadPyodide } from "pyodide";
import TestCaseResultModel from "../../models/submitResultModel";
import TestCaseModel from "../../models/testCaseModel";

interface ISubmitChecker {
  testCases: TestCaseModel[];
  userCode: string;
  onSubmitCheckEnd: (result: TestCaseResultModel[]) => void;
}

const SubmitChecker: React.FC<ISubmitChecker> = ({ testCases, userCode, onSubmitCheckEnd }) => {
  const logs = useRef<string>("");

  useEffect(() => {
    loadPyodide({
      indexURL: "/static/pyodide/",
      stdout: (msg: string) => {
        logs.current = logs.current + (logs.current.length > 0 ? "\n" : "") + msg;
      },
      stderr: (msg: string) => {
        logs.current = logs.current + (logs.current.length > 0 ? "\n" : "") + msg;
      },
    }).then((pyodide: PyodideInterface) => {
      const resultList: TestCaseResultModel[] = [];

      testCases.map((testCase: TestCaseModel) => {
        try {
          pyodide.runPython(userCode);
        } catch (error: any) {
          logs.current = logs.current + (logs.current.length > 0 ? "\n" : "") + error.message;
        } finally {
          resultList.push({
            testCase: testCase,
            result: logs.current === testCase.expectedOutput,
          });
          logs.current = "";
        }
      });

      onSubmitCheckEnd(resultList);
    });
  }, []);
  return <></>;
};

export default SubmitChecker;
