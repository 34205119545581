import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "../redux/store";
import { setUserData } from "../redux/userSlice";
import { getBearerToken } from "./session";
import { BASE_URL } from "./constants";

export const httBaseAuth = (header?: AxiosRequestConfig["headers"]) => {
  return httpBase({
    Authorization: getBearerToken(),
    ...header,
  });
};

export const httpBase = (header?: AxiosRequestConfig["headers"]) => {
  const headers = {
    ...header,
    Accept: "*/*, application/json, text/plain",
  };

  const api = axios.create({
    baseURL: BASE_URL,
    headers: headers,
  });

  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(setUserData(undefined));
      }
      return Promise.reject(error);
    }
  );

  return api;
};
