import colors from "./colors.module.scss";

type Colors = {
  BACKGROUND: string;
  PRIMARY: string;
  SECONDARY: string;
  LIGHT_WHITE: string;
  LIGHT_GRAY: string;
  WHITE: string;
  BLACK_WITH_ALFA: string;
  DANGER: string;
  DARK_BLUE: string;
  YELLOW: string;
};

export const Colors = colors as Colors;
