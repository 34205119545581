import { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./CourseMaterial.scss";
import { getStudentCourseMaterialList } from "../../services/student.api/couseMaterial";
import { CourseMaterialModel } from "../../contracts/models/student/CourseMaterialModel";
import { AxiosResponse } from "axios";
import { CourseMaterialListResponse } from "../../contracts/responses/student/CouseMaterialListResponse";
import PaginationRow from "../PaginationRow/PaginationRow";
import { DEFAULT_PAGINATION_COUNT } from "../../utils/constants";
import NotesIcon from "@mui/icons-material/Notes";
import { Box, Modal, Typography } from "@mui/material";
import Linkify from "linkify-react";

const paperModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CourseMaterialList = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(1);
  const [courseMaterialList, setCourseMaterialList] = useState<CourseMaterialModel[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<CourseMaterialModel>();

  useEffect(() => {
    getStudentCourseMaterialList(1).then((response: AxiosResponse<CourseMaterialListResponse>) => {
      setCourseMaterialList(response.data.course_material_list);
      setTotalCount(response.data.total_count);
    });
  }, [pageNum]);

  return (
    <>
      <div className="material">
        <div className="material__title">
          <div className="material__title__index">
            <AttachFileIcon fontSize="small" />
          </div>
          <div className="material__title__text">Course Material List</div>
        </div>
        <div className="material__description">You can access the course materials shared by your instructor from this area.</div>
        <div className="material__divider" />
        {courseMaterialList.map((material) => {
          return (
            <div className="material__row" onClick={() => setSelectedMaterial(material)}>
              <div className="material__row__icon">
                <NotesIcon fontSize="small" />
              </div>
              <div className="material__row__text"> {material.title}</div>
            </div>
          );
        })}
        <PaginationRow
          page={pageNum}
          count={Math.ceil(totalCount / DEFAULT_PAGINATION_COUNT)}
          onChange={(event, page) => setPageNum(page)}
        />
      </div>
      <Modal open={Boolean(selectedMaterial)} onClose={() => setSelectedMaterial(undefined)}>
        <Box sx={paperModalStyle}>
          <Typography variant="h6">
            <b>{selectedMaterial?.title}</b>
          </Typography>
          <div className="mt-5">
            <Linkify
              componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
                <a href={decoratedHref} key={key} target="_blank">
                  {decoratedText}
                </a>
              )}
            >
              {selectedMaterial?.description}
            </Linkify>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CourseMaterialList;
