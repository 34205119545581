import { Button, Tab, Tabs } from "@mui/material";
import PageHeaderBar from "../../../components/PageHeaderBar/PageHeaderBar";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CodeIcon from "@mui/icons-material/Code";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { RootState } from "../../../redux/types";
import { getInstructorClassDetail } from "../../../services/instructor.api/class";
import { setClassData } from "../../../redux/userSlice";
import { AxiosError, AxiosResponse } from "axios";
import ClassModel from "../../../models/classModel";
import { InstructorContentContext } from "../../../contexts/InstructorContentContext";
import StudentList from "./components/StudentList/StudentList";
import HomeworkSubmisions from "./components/SubmissionList/SubmissionList";
import "./classDetail.scss";

interface IClassDetailPage {}

const ClassDetailPage: React.FC<IClassDetailPage> = ({}) => {
  const dispatch = useDispatch();

  const { userData: user, classData } = useSelector((state: RootState) => state.user);

  const { currentTabIndex, setCurrentTabIndex } = useContext(InstructorContentContext);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {
    if (user?.classId) {
      getInstructorClassDetail(user?.classId)
        .then((response: AxiosResponse<ClassModel>) => {
          dispatch(setClassData(response.data));
        })
        .catch((e: AxiosError) => console.error(e));
    }
  }, [user?.classId]);

  return (
    <>
      <PageHeaderBar />
      <div className="class-container">
        <div className="class-container__aligner">
          <div className="class-container__main-card">
            <div className="class-container__main-card__up-title">Class Detail</div>
            <div className="class-container__main-card__title">{classData?.name}</div>
            <div className="class-container__main-card__meta">
              <div className="class-container__main-card__meta__level">
                <span
                  className={`class-container__main-card__meta__level__icon class-container__main-card__meta__level__${
                    classData?.is_active ? "active" : "expired"
                  }`}
                />
                <span>{classData?.is_active ? "Active" : "Expired"}</span>
              </div>
              <div className="class-container__main-card__meta__chip">
                <AccessTimeIcon fontSize="small" />
                {classData?.total_week_count} Weeks
              </div>
              <div className="class-container__main-card__meta__chip">
                <CodeIcon fontSize="small" />
                {(classData?.active_hw_list?.length ?? 0) + (classData?.not_active_hw_list?.length ?? 0)}
                &nbsp;Total Homeworks
              </div>
            </div>
          </div>
          <div className="class-container__description">
            <div className="class-container__description__title">Course Description</div>
            <div className="class-container__description__text">{classData?.course_description}</div>
          </div>
          <div className="class-container__content" id="class-content">
            <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="Instructor Class Content">
              <Tab label="Student List" />
              <Tab label="Homework Submissions" />
            </Tabs>
            <StudentList />
            <HomeworkSubmisions />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassDetailPage;
