import { useContext, useEffect, useState } from "react";
import { InstructorContentContext } from "../../../../../contexts/InstructorContentContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/types";
import { Box, IconButton, Modal, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SubmissionDetail from "../SubmissionDetail/SubmissionDetail";
import { getInstructorSubmissionList } from "../../../../../services/instructor.api/submission";
import { AxiosResponse } from "axios";
import { SubmissionListResponse } from "../../../../../contracts/responses/instructor/SubmissionListResponse";
import { SubmissionModel } from "../../../../../models/submissionModel";
import PaginationRow from "../../../../../components/PaginationRow/PaginationRow";
import { DEFAULT_PAGINATION_COUNT } from "../../../../../utils/constants";

const paperModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const HomeworkSubmisions = () => {
  const { currentTabIndex, hwSubmissionList, setHwSubmissionList, hwPage, setHWPage } = useContext(InstructorContentContext);
  const [selectedSubmission, setSelectedSubmission] = useState<SubmissionModel>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const classId = useSelector((state: RootState) => state.user.userData?.classId);

  useEffect(() => {
    if (classId) {
      getInstructorSubmissionList(classId, hwPage).then((response: AxiosResponse<SubmissionListResponse>) => {
        setHwSubmissionList(response.data?.submission_list);
        setTotalCount(response.data?.total_count);
      });
    }
  }, [hwPage, classId]);

  return currentTabIndex === 1 ? (
    <>
      <div style={{ marginTop: "12px" }}>
        <TableContainer component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Homework Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hwSubmissionList.map((value: SubmissionModel) => {
              return (
                <TableRow>
                  <TableCell>{value.hw_submission_id}</TableCell>
                  <TableCell>{value.student.username}</TableCell>
                  <TableCell width={"100%"}>{value.homework_name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => setSelectedSubmission(value)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TableContainer>
        <PaginationRow
          page={hwPage}
          count={Math.ceil(totalCount / DEFAULT_PAGINATION_COUNT)}
          onChange={(event, page) => setHWPage(page)}
        />
      </div>
      <Modal open={!!selectedSubmission} onClose={() => setSelectedSubmission(undefined)}>
        <Box sx={paperModalStyle}>
          <SubmissionDetail submission_id={selectedSubmission?.hw_submission_id ?? -1} />
        </Box>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default HomeworkSubmisions;
