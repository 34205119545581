import { httBaseAuth } from "../../utils/httpBase";

export const getStudentHomeworkDetail = (id: string) => {
  return httBaseAuth().get("/student/homework-detail/" + id);
};

export const postStudentSendSubmission = (
  hw_id: string,
  submitted_code: string
) => {
  return httBaseAuth().post("/student/send-submission/", {
    hw_id,
    submitted_code,
  });
};
