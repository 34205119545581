import { Button, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import PythonIcon from "../../assets/python.svg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { AuthContext } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import "./pageHeaderBar.scss";

interface IPageHeaderBar {}

const PageHeaderBar: React.FC<IPageHeaderBar> = ({}) => {
  const user = useSelector((state: RootState) => state.user.userData);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="page-header-bar">
      <Button className="page-header-bar__home-btn" onClick={handleHomeClick}>
        <img src={PythonIcon} alt="" width={40} height={40} />
        <span className="page-header-bar__home-btn__label">Python Courses</span>
      </Button>
      {/* <TextField
        placeholder="Search"
        className="page-header-bar__search"
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      /> */}
      <IconButton
        onClick={handleClick}
        style={{ marginLeft: "auto", marginRight: 30 }}
      >
        <SettingsIcon color="primary" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock
      >
        <div className="page-header-bar__menu-info">
          <div className="page-header-bar__menu-info__row">
            <SchoolIcon /> {user?.school_name}
          </div>
          <div className="page-header-bar__menu-info__row">
            <PersonIcon /> {user?.username}
          </div>
        </div>
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <LogoutIcon fontSize="small" />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PageHeaderBar;
