import "./hwDetailHeaderBar.scss";
import PythonLogo from "../../assets/python.svg";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import HomeworkModel from "../../models/homeworkModel";

interface IHWDetailHeaderBar {
  homework?: HomeworkModel;
}

const HWDetailHeaderBar: React.FC<IHWDetailHeaderBar> = ({ homework }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <div className="hw-detail-header">
      <div className="hw-detail-header__home-btn">
        <Tooltip title="Go to home">
          <IconButton sx={{ padding: 0 }} onClick={handleHomeClick}>
            <img src={PythonLogo} width={40} height={40} />
          </IconButton>
        </Tooltip>
      </div>

      <div className="hw-detail-header__breadcrumb">
        <Button variant="text">Learn</Button>/
        <Button variant="text">Homeworks</Button>/
        <Button variant="text">
          <strong>{homework?.title}</strong>
        </Button>
      </div>

      {/* <div className="hw-detail-header__navigator">
          <div
            className="hw-detail-header__navigator__prev"
            onClick={handleBackClick}
          >
            <Tooltip title="Previous Exercise">
              <ArrowBackIcon fontSize="small" />
            </Tooltip>
          </div>
          <div
            className="hw-detail-header__navigator__outline"
            onClick={handleClickOpen}
          >
            <MenuIcon fontSize="small" />
            Course Outline
          </div>
          <div
            className="hw-detail-header__navigator__next"
            onClick={handleNextClick}
          >
            <Tooltip title="Next Exercise">
              <ArrowForward fontSize="small" />
            </Tooltip>
          </div>
        </div> */}

      {/* <div className="hw-detail-header__level">
        <Tooltip title="Exercise Level">
          <div>
            <span className="hw-detail-header__level__icon" />
            <span>{homework?.level}</span>
          </div>
        </Tooltip>
      </div> */}
    </div>
  );
};

export default HWDetailHeaderBar;
