export const BASE_URL = "https://code.shatranj.ai/api";
// export const BASE_URL = "http://localhost:8000/api";

export const LOCALE_DATE_OPTIONS: Intl.DateTimeFormatOptions | undefined = {
  weekday: "long", // full name of the day (e.g., "Monday")
  year: "numeric", // full numeric representation of the year (e.g., "2024")
  month: "long", // full name of the month (e.g., "January")
  day: "numeric", // numeric representation of the day (e.g., "25")
  hour: "numeric", // numeric representation of the hour (e.g., "13" for 1 PM)
  minute: "numeric", // numeric representation of the minute (e.g., "05")
};

export const DEFAULT_PAGINATION_COUNT = 5;
