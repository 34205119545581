import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/types";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { userData: user } = useSelector((state: RootState) => state.user);

  if (!isLoggedIn) {
    return <Navigate to="/signin" replace />;
  } else if (
    user?.role === "student" &&
    window.location.pathname.includes("instructor")
  ) {
    return <Navigate to="/student/class-detail" replace />;
  } else if (
    user?.role === "instructor" &&
    window.location.pathname.includes("student")
  ) {
    return <Navigate to="/instructor/class-detail" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
