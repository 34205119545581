import { Box, Modal, ModalProps } from "@mui/material";

const paperModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PaperModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal {...props} keepMounted>
      <Box sx={paperModalStyle}>{props.children}</Box>
    </Modal>
  );
};

export default PaperModal;
