import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import StudentClassDetailPage from "./pages/Student/ClassDetailPage/ClassDetailPage";
import InstructorClassDetailPage from "./pages/Instructor/ClassDetailPage/ClassDetailPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import HomeworkDetailPage from "./pages/Student/HomeworkDetailPage/HomeworkDetailPage";
import SigninPage from "./pages/SigninPage/SigninPage";
import { InstructorContentProvider } from "./contexts/InstructorContentContext";
import { Colors } from "./style/colors";

const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: "Studio-Feixen-Sans",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: Colors.PRIMARY,
    },
  },
});

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/signin" replace />} />
              <Route path="/signin" element={<SigninPage />} />
              <Route
                path="/student/class-detail"
                element={
                  <ProtectedRoute>
                    <StudentClassDetailPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/student/homework/:id"
                element={
                  <ProtectedRoute>
                    <HomeworkDetailPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/instructor/class-detail"
                element={
                  <ProtectedRoute>
                    <InstructorContentProvider>
                      <InstructorClassDetailPage />
                    </InstructorContentProvider>
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<Navigate to="/signin" replace />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
