import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Editor } from "@monaco-editor/react";
import { getInstructorSubmissionDetail } from "../../../../../services/instructor.api/submission";
import { LOCALE_DATE_OPTIONS } from "../../../../../utils/constants";
import { SubmissionModel } from "../../../../../models/submissionModel";

interface ISubmissionDetail {
  submission_id: number;
}

const SubmissionDetail: React.FC<ISubmissionDetail> = ({ submission_id }) => {
  const [submissionDetail, setSubmissionDetail] = useState<SubmissionModel>();

  useEffect(() => {
    if (submission_id) {
      getInstructorSubmissionDetail(submission_id).then((response) => {
        setSubmissionDetail(response.data);
      });
    }
  }, [submission_id]);

  return (
    <div>
      <Typography variant="h5">Homework Submission Detail</Typography>
      <Typography variant="body1" sx={{ marginTop: "12px" }}>
        <b>Student: </b> {submissionDetail?.student_name}
      </Typography>
      <Typography variant="body1">
        <b>Homework Name: </b> {submissionDetail?.homework_name}
      </Typography>
      <Typography variant="body1">
        <b>Submitted At: </b>
        {new Date(submissionDetail?.submitted_at ?? "").toLocaleDateString("tr-TR", LOCALE_DATE_OPTIONS)}
      </Typography>
      <div style={{ marginTop: "12px" }}>
        <Editor
          options={{ fontSize: 16, readOnly: true }}
          height="300px"
          width="100%"
          theme={"vs-dark"}
          language={"python"}
          value={submissionDetail?.submitted_code}
          defaultValue={submissionDetail?.submitted_code}
        />
      </div>
    </div>
  );
};

export default SubmissionDetail;
