import { Pagination, PaginationProps } from "@mui/material";
import "./paginationRow.scss";

const PaginationRow: React.FC<PaginationProps> = (props) => {
  return (
    <div className="pagination-row">
      <Pagination {...props} />
    </div>
  );
};

export default PaginationRow;
